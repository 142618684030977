@import '../variables';
@import '../functions';

html {
    // Add splash page for nav bar.
    &.HideOverflow {
        @include small-screen {
            height: 100vh;
            width: 100vw;
            overflow: hidden;
        }
        @include big-and-medium-screen {
            overflow: auto;
        }
    }
}

// Stick footer on bottom.
#App {
    display: flex;
    flex-flow: column nowrap;
    min-height: 100vh;
    #Footer {
        flex-grow: 1;
    }
}

// body {
//     @include big-screen {
//         padding-bottom: 200px;
//     }
//     @include medium-screen {
//         padding-bottom: 100px;
//     }
// }