@import '../../../variables';

#GemmaqStateAverage {
    display: flex;
    flex-flow: column nowrap;
    .ErrorWrapper {
        color: red;
    }
    .TableHeaderWrapper {
        // Space from table
        padding-bottom: 10px;
        // Flex settings.
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        p {
            margin: 0px;
            color: #757575;
            &.FirstTableHeader {
                padding-left: 5px;
            }
            &.LastTableHeader {
                padding-right: 5px;
            }
        }
        .TableHeaderJustifyRight {
            flex: 1 0 50px;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;
            align-items: center;
        }
    }
    table {
        border-bottom: 2px solid #E4E3E3;
        tr {
            td {
                &:nth-child(2) {
                    text-align: right;
                }
                &:nth-child(3) {
                    text-align: right;
                    width: 60px;
                }
                a {
                    color: #231F20;
                }
                color: #231F20;
                border-top: 0px;
                padding: {
                    top: 0px;
                    left: 5px;
                    right: 5px;
                    bottom: 0px;
                }
                &.RatingTd {
                    font-weight: bold;
                }
                &.RatingChangeTd {
                    &.Positive {
                        color: #278465;
                    }
                    &.Negative {
                        color: #F85F42;
                    }
                    svg {
                        margin-right: 3px;
                    }
                }
            }
            &:nth-of-type(odd) {
                background-color: #F8F8F8;
            }
        }
    }
}