@import '../../variables';
@import '../../functions';

$normal-height: 45px;
$big-height: 55px;

.SearchBar {
    // Anchor the absolute icon.
    position: relative;
    // Default styling for input.
    .SearchBarInput {
        // Size.
        width: 100%;
        height: $normal-height;
        border-radius: calc($normal-height / 2);
        // Move placeholder text left.
        padding-left: calc($normal-height / 2);
        // Colors.
        background: #FAFAFA;
        box-shadow: inset 0px 1px 7px rgba(0, 0, 0, 0.14);
        &.IsFocused {
            // Override bootstrap focus border.
            border: 3px solid #4267B2;
        }
    }
    // Default styling for input.
    .SearchBarResults {
        // Hide by default.
        display: none;
        // Same width as parent
        width: 100%;
        // Float so it doesn't move content.
        position: absolute;
        z-index: 999;
        // Borders.
        border: 3px solid #4267B2;
        border-bottom-right-radius: calc($normal-height / 2);
        border-bottom-left-radius: calc($normal-height / 2);
        border-top: 0px;
        // Colors.
        box-shadow: inset 0px 1px 7px rgba(0, 0, 0, 0.14);
        background: #FAFAFA;
        // Align vertically.
        flex-flow: column nowrap;
        a {
            // Font
            color: #231F20;
            font-weight: 400;
            font-style: normal;
            font-size: 14px;
            line-height: 17px;
            // Size
            height: 45px;
            // Center text
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;
            // Move text
            padding-left: calc($normal-height / 2);
            &:hover {
                cursor: pointer;
                text-decoration: none;
            }
            &:focus {
                background-color: #4267B2;
                color: white;
                .WatchlistIcon {
                    color: white !important;
                }
            }
            &:last-child {
                border-bottom-right-radius: calc($normal-height / 2);
                border-bottom-left-radius: calc($normal-height / 2);
            }
            // Override transition effect.
            .WatchlistIcon {
                transition: none !important;
            }
        }
    }
    // Position icon absolutely.
    .SearchIcon {
        position: absolute;
        right: calc($normal-height / 2);
        top: 15px;
        &:hover {
            cursor: pointer;
        }
    }
    &.ShowResults {
        .SearchBarInput {
            // Remove bottom border and radii.
            border-bottom: 0px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        }
        // Only show results if hasResults and input is focused.
        .SearchBarResults {
            display: flex;
        }

    }
    &.IsBig {
        @include big-and-medium-screen {
            &.ShowResults {
                .SearchBarInput {
                    // Remove bottom border and radii.
                    border-bottom: 0px;
                    border-bottom-left-radius: 0px;
                    border-bottom-right-radius: 0px;
                }
            }
            // Make everything bigger!!
            .SearchBarInput {
                height: $big-height;
                border-radius: calc($big-height / 2);
                padding-left: calc($big-height / 2);
            }
            .SearchBarResults {
                border-bottom-right-radius: calc($big-height / 2);
                border-bottom-left-radius: calc($big-height / 2);
                a:last-child {
                    border-bottom-right-radius: calc($big-height / 2);
                    border-bottom-left-radius: calc($big-height / 2);
                }
            }
            .SearchIcon {
                top: 20px;
                right: calc($big-height / 2);
            }
        }
    }
}