/* THRESHOLD VARIABLES */
$big-screen-threshold: 1200px; // BOOTSTRAP XL
$medium-screen-max-threshold: $big-screen-threshold - 0.01px;
$medium-screen-min-threshold: 768px; // BOOTSTRAP MEDIUM
$small-screen-threshold: $medium-screen-min-threshold - 0.01px;

/* SIZE VARIABLES */
// Max width for content
$max-content-width: 1600px;

/* COLOR VARIABLES */