@import '../../variables';
@import '../../functions';

$gemmaq-donut-desktop-width: 460px;
$gemmaq-donut-desktop-height: 280px;
$gemmaq-donut-mobile-width: 400px;
$gemmaq-donut-mobile-height: 230px;

.GemmaqChart {
    // Sizes are HARD-CODED!
    width: $gemmaq-donut-desktop-width;
    height: $gemmaq-donut-desktop-height;
    @include medium-and-small-screen {
        width: $gemmaq-donut-mobile-width;
        height: $gemmaq-donut-mobile-height;
    }
    // Chart element is bigger than the container. Highcharts adds invisible padding with the height parameter.
    overflow: hidden;
    // Anchor this element so the text elements can absolutely be positioned.
    position: relative;
    .ChartElement {
        // Center chart vertically.
        margin-top: -10px;
        tspan {
            font-family: 'Montserrat',-apple-system, BlinkMacSystemFont, sans-serif;
        }
    }
    .TextContainer {
        position: absolute;
        top: 0px;
        display: flex;
        flex-flow: row wrap;
        .DataWrapper {
            // Half height and half width of whole gemmaq chart area
            width: calc($gemmaq-donut-desktop-width / 2 - 1px);
            height: calc($gemmaq-donut-desktop-height / 2 - 1px);
            @include medium-and-small-screen {
                width: calc($gemmaq-donut-mobile-width / 2 - 1px);
                height: calc($gemmaq-donut-mobile-height / 2 - 1px);
            }
            // Texts are on top of eachother.
            display: flex;
            flex-flow: column nowrap;
            .Title {
                font-size: 14px;
                line-height: 18px;
                @include medium-and-small-screen {
                    font-size: 12px;
                    line-height: 14px;
                }
                // Add space between title and (color + value).
                margin-bottom: 4px;
            }
            .ColorValueContainer {
                // Value and color next to each other.
                display: flex;
                flex-flow: row nowrap;
                .Value {
                    // Font.
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: bold;
                    @include medium-and-small-screen {
                        font-size: 12px;
                        line-height: 14px;
                    }
                }
                .ColorBlock {
                    height: 18px;
                    width: 8px;
                    border-radius: 2px;
                    @include medium-and-small-screen {
                        height: 15px;
                        width: 7px;
                    }
                }
            }
            // Add specific settings for each corner
            &.Ceo-Wrapper {
                padding-right: 86px;
                @include medium-and-small-screen {
                    padding-right: 72px;
                }
                justify-content: flex-start;
                align-items: flex-end;
                .ColorBlock {
                    order: 1;
                    margin-left: 5px;
                }
            }
            &.Chair-Wrapper {
                padding-left: 86px;
                @include medium-and-small-screen {
                    padding-left: 72px;
                }
                justify-content: flex-start;
                align-items: flex-start;
                .ColorBlock {
                    order: -1;
                    margin-right: 5px;
                }
            }
            &.Top-Wrapper {
                padding-right: 86px;
                @include medium-and-small-screen {
                    padding-right: 72px;
                }
                justify-content: flex-end;
                align-items: flex-end;
                .ColorBlock {
                    order: 1;
                    margin-left: 5px;
                }
            }
            &.Bod-Wrapper {
                padding-left: 86px;
                @include medium-and-small-screen {
                    padding-left: 72px;
                }
                justify-content: flex-end;
                align-items: flex-start;
                .ColorBlock {
                    order: -1;
                    margin-right: 5px;
                }
            }
        }
    }
}

// .GemmaqChartViewport {
//     // Sizes are similar to hard-coded values, but width takes up all available space.
//     width: 100%;
//     height: $gemmaq-donut-desktop-height;
//     @include medium-and-small-screen {
//         height: $gemmaq-donut-mobile-height;
//     }
//     // Allow GemmaqChart to remain at hardcoded size even when viewport shrinks beyond that
//     overflow: hidden;
//     display: flex;
//     flex-flow: row nowrap;
//     justify-content: center;
//     align-items: flex-start;
//     position: relative;
// }