@import './variables';

@mixin big-screen {
    @media (min-width: $big-screen-threshold) {
        @content;
    }
}

@mixin big-and-medium-screen {
    @media (min-width: $medium-screen-min-threshold) {
        @content;
    }
}

@mixin medium-screen {
    @media (min-width: $medium-screen-min-threshold) and (max-width: $medium-screen-max-threshold) {
        @content;
    }
}

@mixin medium-and-small-screen {
    @media (max-width: $medium-screen-max-threshold) {
        @content;
    }
}

@mixin small-screen {
    @media (max-width: $small-screen-threshold) {
        @content;
    }
}