@import '../../variables';
@import '../../functions';

#CompanyProfile {
    max-width: $max-content-width;
    width: 100%;
    margin: {
        left: auto;
        right: auto;
    }
    h1 {
        font-size: 44px;
        line-height: 54px;
    }
    h2 {
        font-size: 22px;
        line-height: 27px;
        font-weight: 400;
    }
    .PageHeader {
        overflow-wrap: break-word;
        @include small-screen {
            text-align: center;
        }
    }
    .WatchlistButtonCol {
        @include big-and-medium-screen {
            padding-top: 15px;
            .WatchlistIconButton {
                float: right
            }
        }
        @include small-screen {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            padding-bottom: 15px;
        }
    }
    .ChartWrapper {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: flex-start;
    }
    .GenderCardWrapper {
        @include small-screen {
            margin-top: 20px;
        }
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
        .GenderCardDivider {
            height: 20px;
            flex-basis: 20px;
        }
        .GenderCard {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            padding: 32.5px 30px;
            &.Male {
                background-color: #D9E0F2;
            }
            &.Female {
                background-color: #FCE8EC;
            }
            &.NotSet {
                background-color: rgba(125, 125, 125, 0.2);
            }
            .GenderCardTitle {
                width: 80px;
                font-weight: 600;
                font-style: normal;
                font-size: 16px;
                line-height: 19.5px;
            }
            .GenderCardName {
                flex-grow: 1;
                font-weight: 400;
                font-style: normal;
                font-size: 18px;
                line-height: 30px;
                text-transform: capitalize;
            }
            .GenderCardIcon {
                .FaMale {
                    color: #2E5B8A;
                }
                .FaFemale {
                    color: #EF8AA1;
                }
            }
        }
    }
    .CompanyDataWrapper {
        @include big-screen {
            margin-top: 30px;
        }
        @include medium-screen {
            margin-top: 40px;
        }
        @include small-screen {
            margin-top: 40px;
        }
        .CompanyDataHeader {
            margin-bottom: 10px;
        }
        .CompanyDataTable {
            background-color: #F8F8F8;
            padding: 12.5px 10px;
            table {
                height: 100%;
                width: 100%;
                tbody {
                    tr {
                        td {
                            padding: {
                                top: 7.5px;
                                bottom: 7.5px;
                                left: 10px;
                                right: 40px;
                            }
                            font-size: 14px;
                            line-height: 22px;
                            &:not(:first-child) {
                                font-weight: bold;
                            }
                        }
                    }
                }
            }
        }
    }
    .GemmaqRatingsWrapper {
        @include big-screen {
            margin-top: 60px;
        }
        @include medium-screen {
            margin-top: 80px;
        }
        @include small-screen {
            margin-top: 80px;
        }
        .GemmaqRatingsHeader {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            margin-bottom: 10px;
            .FaChevronCircleRight {
                margin-right: 10px;
            }
        }
        .GemmaqRatings {
            background-color: #F8F8F8;
            display: flex;
            @include big-and-medium-screen {
                flex-flow: row nowrap;
            }
            @include small-screen {
                flex-flow: column nowrap;
            }
            justify-content: space-around;
            align-items: center;
            padding: {
                top: 40px;
                bottom: 40px;
            }
            .GemmaqRatingsCard {
                @include small-screen {
                    margin-top: 10px;
                }
                .Rating {
                    text-align: center;

                    font-style: normal;
                    font-weight: 600;
                    font-size: 44px;
                    line-height: 54px;

                    color: #231F20;
                }
                .Title {
                    margin-top: 10px;
                    text-align: center;

                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 17px;

                    color: #231F20;
                }
            }
        }
    }
    .FinancialDataWrapper {
        @include medium-screen {
            margin-top: 40px;
        }
        @include small-screen {
            margin-top: 40px;
        }
        .FinancialDataHeader {
            margin-bottom: 10px;
        }
        .FinancialData {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            gap: 5px;
            .FinancialDataRow {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                gap: 5px;
                width: 100%;
                .FinancialDataCard {
                    background-color: #F8F8F8;
                    width: calc((100% - 10px)/3);
                    padding: 20px 15px;
                    @include small-screen {
                        margin-top: 10px;
                    }
                    &.pos {
                        background-color: #DDF5EC;
                        .Value, .Title {
                            color: #247A5D;
                        }
                    }
                    &.neg {
                        background-color: #F85F421F;
                        .Value, .Title {
                            color: #F85F42;
                        }
                    }
                    .Value {
                        text-align: center;

                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 22px;

                        color: #231F20;

                        .FaArrow {
                            margin-right: 4px;
                        }
                    }
                    .Title {
                        margin-top: 6px;
                        text-align: center;

                        font-style: normal;
                        font-weight: normal;
                        font-size: 13px;
                        line-height: 16px;

                        color: #231F20;
                    }
                }
            }
        }
    }
}