@import '../../variables';
@import '../../functions';
// Importing .MNav class
@import '../../index.scss';

#NavigationBar {
    // Override bootstrap padding.
    padding: 0px;
    // Align mobile search bar.
    display: flex;
    flex-flow: column nowrap;
    .NavigationBarWrapper {
        // Anchor LeftArea in mobile.
        position: relative;
        // Add white space from top and sides.
        @include big-and-medium-screen {
            padding: {
                top: 22px;
                left: 33px;
                right: 30px;
            }
        }
        @include small-screen {
            padding: {
                top: 11px;
                left: 15px;
                right: 17px;
            };
        }
        // Set max width. Adds whitespace on R/L when screen is huge.
        max-width: $max-content-width;
        width: 100%;
        margin: {
            left: auto;
            right: auto;
        }
        // Flex.
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-flow: row nowrap;
        .NavigationBarLeftArea {
            box-sizing: content-box;
            display: flex;
            text-align: left;
            @include big-screen {
                width: calc(100% / 3);
            }
            @include medium-screen {
                width: 30%;
            }
            @include small-screen {
                position: absolute;
            }
            a {
                box-sizing: content-box;
                margin-right: 0px;
                line-height: 0;
                padding: 0px;
                img {
                    box-sizing: content-box;
                    width: 77px;
                    // Shrink logo on mobile
                    @include small-screen {
                        width: 47px;
                    }
                }
            }
        }
        .NavigationBarCenterAndRightArea {
            // Change size on mobile.
            @include big-screen {
                width: calc(2 * 100% / 3);
            }
            @include medium-screen {
                width: 70%;
            }
            @include small-screen {
                flex-grow: 1;
            }
            .NavigationBarCraTop {
                height: 70px;
                display: flex;
                flex-flow: column nowrap;
                .ButtonContainer {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-end;
                    .NavigationHamburgerButtonWrapper {
                        // Conditional renders for mobile.
                        @include big-and-medium-screen {
                            display: none;
                        }
                        @include small-screen {
                            display: block;
                        }
                        .NavigationHamburgerButton {
                            // Override buttons styles.
                            background-color: white;
                            border: 0px;
                            // Add space between sign in button. Not in svg for fat fingers.
                            margin-right: 18px;
                            svg {
                                // Style.
                                width: 25px;
                                height: 25px;
                                color: #231F20;
                                // Add space from top (inside button for fat fingers).
                                margin-top: 8px;
                            }
                        }
                    }
                    .NavBarLinkWrapper {
                        // Align text in the right way.
                        display: flex;
                        flex-flow: row nowrap;
                        justify-content: flex-start;
                        align-items: flex-start;
                        padding-top: 10px;
                        // Hide on mobile.
                        @include small-screen {
                            display: none;
                        }
                        .NavBarLink:hover {
                            text-decoration: none;
                        }
                        .NavBarDropdownItem {
                            position: relative;
                            &:hover {
                                .NavBarLink {
                                    color: #4353D8;
                                }
                                @include big-and-medium-screen {
                                    .NavBarDropdown {
                                        opacity: 1;
                                        visibility: visible;
                                    }
                                    .icon svg {
                                        transform: rotate(180deg);
                                    }
                                }
                            }
                            button.NavBarLink {
                                display: flex;
                                gap: 5px;
                                appearance: none;
                                border: none;
                                background: transparent;
                                outline: none;
                            }
                            .NavBarDropdown {
                                position: absolute;
                                background-color: white;
                                box-shadow: 0px 4px 15px 0px rgba(67, 83, 216, 0.35);
                                border-radius: 16px;
                                padding: {
                                    top: 15px;
                                    right: 25px;
                                    bottom: 15px;
                                    left: 15px;
                                }
                                top: calc(100% + 12px);
                                left: -20px;
                                z-index: 999999;
                                display: flex;
                                gap: 15px;
                                align-items: center;
                                opacity: 0;
                                visibility: hidden;
                                transition: opacity .3s, visibility .3s;
                                min-width: 220px;
                                .colorBar {
                                    width: 5px;
                                    align-self: stretch;
                                    background: linear-gradient(180deg, #F08998 0%, #F48367 34%, #298065 65.5%, #3A556A 100%);
                                    border-radius: 12px;
                                }
                                ul {
                                    list-style: none;
                                    margin-bottom: 0px;
                                    li:not(:last-child) {
                                        margin-bottom: 15px;
                                    }
                                }
                                .NavBarDropdownLink {
                                    display: block;
                                    color: #757575;
                                    font-weight: 500;
                                    font-size: 14px;
                                    line-height: 17px;
                                    text-decoration: none;
                                    &:hover {
                                        color: #4353D8;
                                    }
                                }
                            }
                        }
                        .NavBarLink {
                            color: #757575;
                            font-weight: bold;
                            font-size: 15px;
                            line-height: 18px;
                            margin-right: 30px;
                            white-space: nowrap;
                            &:last-child {
                                margin-right: 40px;
                            }
                            .icon {
                                font-size: 12px;
                                svg {
                                    transition: transform .3s;
                                }
                            }
                            @include medium-screen {
                                font-size: 14px;
                                line-height: 16px;
                                margin-right: 10px;
                                &:last-child {
                                    margin-right: 20px;
                                }
                            }
                        }
                    }
                    .SignInButton {
                        // Creating "fake" border.
                        background: linear-gradient(260.53deg, #EF8AA1 5.47%, #F48363 32.59%, #278465 59.26%, #3A556A 90.83%);
                        width: 100px;
                        height: 40px;
                        border-radius: 20px;
                        // Center text in the middle.
                        display: flex;
                        flex-flow: row nowrap;
                        justify-content: center;
                        align-items: center;
                        &:hover {
                            // Show cursor it is clickable
                            cursor: pointer;
                            // Remove a tag underline.
                            color: #231f20;
                            text-decoration: none;
                            .SignInText {
                                background-color: transparent;
                                color: white;
                            }
                        }
                        .SignInText {
                            // Finishing fake border with white insides.
                            background-color: white;
                            border-radius: 17px;
                            width: 94px;
                            height: 34px;
                            // Font.
                            color: #231f20;
                            font-weight: bold;
                            // Center the text.
                            display: flex;
                            flex-flow: row nowrap;
                            justify-content: center;
                            align-items: center;
                            // Transition between color states.
                            transition:
                                background-color .3s ease-in-out,
                                color .3s ease-in-out;
                        }

                    }
                }
                .UsernameContainer {
                    // Move text to right.
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-end;
                    // Hide name on small screen
                    @include small-screen {
                        display: none;
                    }
                    .Username {
                        // Space between button
                        margin-top: 20px;
                        // Font.
                        font-size: 13px;
                        line-height: 15px;
                        color: #757575;
                    }
                }
            }
            .NavigationBarCraBottom {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                .SearchBarContainer {
                    @include big-screen {
                        width: 50%;
                    }
                    @include medium-screen {
                        width: calc(40% / 0.7);
                    }
                    @include small-screen {
                        margin-top: 15px;
                        width: 100%;
                    }
                }
            }
        }
    }
    // MOBILE EXPANDED HAMBURGER MENU
    .MobileContentContainer {
        display: none;
    }
    &.is-expanded {
        @include small-screen {
            z-index: 99999999;
            display: block;
            width: 100vw;
            height: 100vh;
            background-color: white;
            .SearchBarContainer {
                display: none;
            }
            .MobileContentContainer {
                display: block;
                // Space between hamburger menu.
                padding-top: 27px;
                .UsernameContainer {
                    height: 24px;
                    .Username {
                        // Font.
                        font-size: 13px;
                        line-height: 15px;
                        color: #757575;
                    }
                }
                .NavBarLinkContainer {
                    margin-top: 25px;
                    display: flex;
                    flex-flow: column nowrap;
                    .NavBarLink {
                        // Space between
                        margin-top: 40px;
                        // Font and style.
                        color: #757575;
                        font-weight: bold;
                        font-size: 20px;
                        line-height: 24px;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                    .NavBarDropdownItem {
                        button.NavBarLink {
                            display: flex;
                            gap: 8px;
                            appearance: none;
                            border: none;
                            background: transparent;
                            outline: none;
                            .icon {
                                font-size: 16px;
                                svg {
                                    transition: transform .3s;
                                }
                            }
                        }
                        .NavBarDropdown {
                            display: none;
                            background-color: white;
                            border-radius: 16px;
                            padding: {
                                top: 25px;
                                right: 25px;
                                bottom: 0px;
                                left: 20px;
                            }
                            gap: 20px;
                            align-items: center;
                            .colorBar {
                                width: 5px;
                                align-self: stretch;
                                background: linear-gradient(180deg, #F08998 0%, #F48367 34%, #298065 65.5%, #3A556A 100%);
                                border-radius: 12px;
                            }
                            ul {
                                list-style: none;
                                margin-bottom: 0px;
                                li:not(:last-child) {
                                    margin-bottom: 15px;
                                }
                            }
                            .NavBarDropdownLink {
                                display: block;
                                color: #757575;
                                font-weight: 500;
                                font-size: 18px;
                                line-height: 21px;
                                text-decoration: none;
                            }
                        }
                        &.is-open {
                            .NavBarLink {
                                color: #4353D8;
                                .icon svg {
                                    transform: rotate(180deg);
                                }
                            }
                            .NavBarDropdown {
                                display: flex;
                            }
                        }
                    }
                }
            }
        }
    }
}

