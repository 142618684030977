.WatchlistIcon {
    margin-right: 10px;
    width: 1em;
    &:hover {
        cursor: pointer;
    }
    &.FaEmptyStar {
        color: #F48363;;
    }
    &.FaFullStar {
        color: #F48363;
    }
    &.FaSpinner {
        color: #cccccc;
        animation: WatchlistIconSpinner 1s infinite linear;
    }
    transition: color .15s ease-in-out;
}

:not(.WatchlistIconButton) {
    .WatchlistIcon {
        &.FaEmptyStar {
            &:hover {
                color: #333333;
            }
        }
    }
}

.WatchlistIconButton {
    // Size
    height: 40px;
    // Font
    color: #F48363 !important;
    white-space: nowrap;
    font-weight: bold;
    // Border
    border-color: #F48363 !important;
    border-width: 3px;
    border-radius: 20px;
    // Style
    box-shadow: 0px 0px 0px 0px !important;
    &:hover {
        background-color: #F48363 !important;
        color: white !important;
        .FaEmptyStar {
            color: white !important;
        }
        .FaFullStar {
            color: white !important;
        }
    }
    &:active {
        background-color: transparent !important;
    }
}

@keyframes WatchlistIconSpinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
}