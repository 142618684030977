.ErrorToast {
    background-color: white;
    border: 2px solid #F85F42;
    .toast-header {
        justify-content: space-between;
        border-bottom: 0px;
        b {
            color: #231F20;
            margin: {
                top: 10px;
                right: 30px;
            }
        }
        .FaTimes {
            color: #F85F42;
            font-size: 16px;
            &:hover {
                cursor: pointer;
            }
        }
    }
    .toast-body {
        padding-top: .5rem;
        p {
            color: #231F20;
        }
    }
}