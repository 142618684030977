@import '../../variables';
@import '../../functions';

#AboutUs {
    max-width: $max-content-width;
    width: 100%;
    margin: {
        left: auto;
        right: auto;
    }
    .PersonCard {
        img.PersonCardImage {
            border-radius: 3px;
            @include big-screen {
                float: right;
                object-fit: cover;
                width: 320px;
                height: 320px;
                margin: 15px;
            }
            @include medium-and-small-screen {
                float: right;
                object-fit: cover;
                width: 220px;
                height: 220px;
                margin: 5px;
            }
        }
    }
    .SmallPersonCard {
        margin-top: 10px;
        display: flex;
        flex-flow: row nowrap;
        img {
            display: inline-block;
            border-radius: 3px;
            @include big-screen {
                object-fit: cover;
                width: 280px;
                height: 280px;
            }
            @include medium-screen {
                object-fit: cover;
                width: 150px;
                height: 150px;
            }
            @include small-screen {
                object-fit: cover;
                width: 150px;
                height: 150px;
            }
        }
        div.SmallPersonCardContent {
            padding-left: 20px;
        }
    }
    h2 {
        margin-top: 40px;
        margin-bottom: 25px;
    }
    h1, h2, h3 {
        color: #231F20;
        font-style: normal;
    }
    h3 {
        font-size: 24px;
        font-weight: bold;
    }
    p {
        color: #231F20;
        font-size: 16px;
        font-style: normal;
        line-height: 28px;
    }
    .AwardAndFundingLogoWrapper {
        display: flex;
        @include big-and-medium-screen {
            flex-flow: row nowrap;
            justify-content: space-around;
        }
        @include small-screen {
            flex-flow: column nowrap;
            align-items: center;
        }
        .AwardAndFundingLogo {
            @include big-screen {
                height: 150px;
            }
            @include medium-screen {
                height: 100px;
            }
            @include small-screen {
                width: 150px;
                margin: 20px;
            }
        }

    }
}