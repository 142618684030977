@import '../../functions';

#Footer {
    // Position
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    .TextContainer {
        // Color:
        background: linear-gradient(90deg, #EF8AA1 0%, #F48363 32.47%, #278465 71.69%, #3A556A 99.09%);
        // Align text in a line
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: stretch;
        // Add initial space
        padding-left: 15px;
        // Size
        height: 25px;
        @include big-screen {
            margin-top: 56px;
        }
        @include medium-screen {
            margin-top: 48px;
        }
        .AlignmentSpace {
            flex-grow: 1;
        }
        a {
            // Font.
            font-size: 13px;
            line-height: 15px;
            color: #ffffff;
            font-weight: bold;
            // Add space between wall
            padding-top: 5px;
            margin-right: 15px;
            // Override default <a> behaviour.
            &:hover {
                text-decoration: none;
                color: #ffffff;
            }
        }
    }
}