@import '../../variables';
@import '../../functions';

#Watchlist {
    max-width: $max-content-width;
    width: 100%;
    margin: {
        left: auto;
        right: auto;
    }
    .PageHeaderRow {
        justify-content: space-between;
        .PageHeader {
            @include small-screen {
                text-align: center;
            }
        }
    }
    h2 {
        color: #231F20;
    }
    .SearchBarCol {
        @include small-screen {
            margin-bottom: 20px;
        }
    }
    .ChartWrapper {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: flex-start;
    }
    .GenderCardWrapper {
        @include medium-and-small-screen {
            margin-top: 46px;
        }
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
        .GenderCardDivider {
            height: 20px;
            flex-basis: 20px;
        }
        .GenderCard {
            height: 95px;
            @include small-screen {
                height: 80px;
            }
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            &.Male {
                background-color: rgba(67, 101, 189, 0.2);
            }
            &.Female {
                background-color: rgba(239, 138, 161, 0.2);
            }
            &.NotSet {
                background-color: rgba(125, 125, 125, 0.2);
            }
            .GenderCardTitle {
                padding: {
                    left: 15px;
                    right: 15px;
                }
                font-weight: 600;
                font-style: normal;
                font-size: 16px;
                line-height: 40px;
            }
            .GenderCardName {
                flex-grow: 1;
                padding: {
                    left: 15px;
                    right: 15px;
                }
                font-weight: 400;
                font-style: normal;
                font-size: 18px;
                line-height: 30px;
                text-transform: capitalize;
            }
            .GenderCardIcon {
                padding: {
                    left: 15px;
                    right: 30px;
                }
                .FaMale {
                    color: #2E5B8A;
                }
                .FaFemale {
                    color: #EF8AA1;
                }
            }
        }
    }
    .WatchlistTableWrapper {
        .TableHeaderWrapper {
            // Space from table
            padding-bottom: 10px;
            // Flex settings.
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;
            p {
                margin: 0px;
                color: #757575;
                &.FirstTableHeader {
                    padding-left: 5px;
                }
                &.LastTableHeader {
                    padding-right: 5px;
                }
            }
            .dropdown {
                p {
                    padding-left: 10px;
                    color: #3A556A;
                }
                #SectorFilterMenu {
                    border-radius: 2px;
                    padding: {
                        top: 0px;
                        bottom: 0px;
                    }
                    a {
                        font-weight: 500;
                        font-style: normal;
                        font-size: 13px;
                        line-height: 30px;
                        color: #231F20;
                        padding: {
                            top: 2px;
                            left: 10px;
                            right: 10px;
                            bottom: 2px;
                        }
                        &.Selected, &:hover {
                            background: linear-gradient(269.86deg, #2E5B8A 9.5%, #278465 96.07%);
                            color: white;
                            font-weight: 600;
                        }
                    }
                }
            }
            .TableHeaderJustifyRight {
                flex: 1 0 50px;
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-end;
                align-items: center;
            }
        }
        table {
            width: 100%;
            height: 100%;
            margin-bottom: 0px;
            tr {
                td {
                    vertical-align: middle;
                    text-align: right;
                    padding: {
                        top: 0px;
                        left: 5px;
                        right: 5px;
                        bottom: 0px;
                    }
                    border: 0px;
                    color: #231F20;
                    &.TextColumn {
                        text-align: left;
                    }
                    &.TableTextRight {
                        white-space: nowrap;
                    }
                    .Negative {
                        color: #F85F42;
                    }
                    a {
                        color: #231F20;
                    }
                    span {
                        display: inline-block;
                        width: 40px;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 38px;
                        color: #757575;
                        &.Positive {
                            color: #278465;
                        }
                        &.Negative {
                            color: #F85F42;
                        }
                    }
                }
                &:nth-of-type(odd) {
                    background-color: #F8F8F8;
                }
            }
        }
    }
    .GemmaqRatingsWrapper {
        @include big-screen {
            margin-top: 40px;
        }
        @include medium-screen {
            margin-top: 80px;
        }
        @include small-screen {
            margin-top: 80px;
        }
        .GemmaqRatingsHeader {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            margin-bottom: 10px;
            .FaChevronCircleRight {
                margin-right: 10px;
            }
        }
        .GemmaqRatings {
            background-color: #F8F8F8;
            display: flex;
            @include big-and-medium-screen {
                flex-flow: row nowrap;
            }
            @include small-screen {
                flex-flow: column nowrap;
            }
            justify-content: space-around;
            align-items: center;
            padding: {
                top: 40px;
                bottom: 40px;
            }
            .GemmaqRatingsCard {
                @include small-screen {
                    margin-top: 10px;
                }
                .Rating {
                    text-align: center;

                    font-style: normal;
                    font-weight: 500;
                    font-size: 44px;
                    line-height: 30px;

                    color: #231F20;
                }
                .Title {
                    margin-top: 10px;
                    text-align: center;

                    font-style: normal;
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 30px;

                    color: #231F20;
                }
            }
        }
    }
}