@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import './variables';
@import './functions';

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

body {
    font-family: 'Montserrat',-apple-system, BlinkMacSystemFont, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* General Single Page */

.PageHeader {
    color: #231F20;
    margin-top: 15px;
    margin-bottom: 25px;
}

.PageHorizontalLine {
    height: 3px;
    width: 100%;
    background: linear-gradient(90deg, #EF8AA1 0%, #F48363 32.47%, #278465 71.69%, #3A556A 99.09%);
    @include big-and-medium-screen {
        margin-top: 25px;
    }
    margin-bottom: .5rem;
}

/* Dashboard */

h2.Header {
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 27px;

    color: #C4C4C4;
}

.TableHeader {
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    text-transform: uppercase;

    color: #C4C4C4;
}

.MNav {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;

    color: #C4C4C4;
}

h1.Header {
    font-style: normal;
    font-weight: normal;
    font-size: 44px;
    line-height: 54px;

    color: #C4C4C4;
}

.TableTextLeft {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 38px;

    color: #C4C4C4;
}

.TableTextRight {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 38px;

    color: #C4C4C4;
}

.NewsLink {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 1;

    color: #2E5B8A;
    &:hover {
        color: #2E5B8A;
    }
}

.NewsSource {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 22px;

    color: #C4C4C4;
}

.NewsTime {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 21px;

    color: #C4C4C4;
}