@import '../../variables';
@import '../../functions';

#Dashboard {
    margin-top: 40px;
}

@include big-screen {
    #Dashboard {
        max-width: $max-content-width;
        width: 100%;
        margin: {
            left: auto;
            right: auto;
        }
        display: grid;
        grid-template-columns: [far-left] 2fr [one-third] 2fr [two-third] 2fr [far-right];
        grid-template-rows: [far-top] auto [mid-line] auto [far-bottom];
        #BiggestCompaniesListWrapper {
            grid-column: far-left / one-third;
            grid-row: far-top / far-bottom;
        }
        #CompositePanelWrapper {
            grid-column: one-third / two-third;
            grid-row: far-top / mid-line;
        }
        #GemmaqAveragesWrapper {
            grid-column: one-third / two-third;
            grid-row: mid-line / far-bottom;
        }
        #TopRatedListWrapper {
            grid-column: two-third / far-right;
            grid-row: far-top / mid-line;
        }
        #BottomRatedListWrapper {
            grid-column: two-third / far-right;
            grid-row: mid-line / far-bottom;
        }
    }
}
@include medium-screen {
    #Dashboard {
        display: grid;
        grid-template-columns: [far-left] 1fr [half-way] 1fr [far-right];
        grid-template-rows: [far-top] auto [one-line] auto [two-line] auto [far-bottom];
        #BiggestCompaniesListWrapper {
            grid-column: far-left / half-way;
            grid-row: far-top / two-line;
        }
        #CompositePanelWrapper {
            grid-column: half-way / far-right;
            grid-row: far-top / one-line;
        }
        #GemmaqAveragesWrapper {
            grid-column: far-left / half-way;
            grid-row: two-line / far-bottom;
        }
        #TopRatedListWrapper {
            grid-column: half-way / far-right;
            grid-row: one-line / two-line;
        }
        #BottomRatedListWrapper {
            grid-column: half-way / far-right;
            grid-row: two-line / far-bottom;
        }
    }
}
