@import '../../variables';
@import '../../functions';

#Methodology {
    max-width: $max-content-width;
    width: 100%;
    margin: {
        left: auto;
        right: auto;
    }
    p {
        color: #231F20;
        font-size: 14px;
        font-style: normal;
        line-height: 25px;
    }
    .ColorMeaningImage {
        @include big-screen {
            float: right;
        }
        @include medium-and-small-screen {
            margin-top: 20px;
            margin-bottom: 40px;
        }
    }
    .ColorMeaningBreakpoint {
        @include big-screen {
            display: none;
        }
    }
    .RatingImageWrapper {
        div {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            padding: 20px;
            .RatingImage {
                width: 90%;
            }
        }
    }
    a {
        color: #2E5B8A !important;
        font-weight: bold;
    }
    .UnderParagraph {
        margin-top: 50px;
    }
}