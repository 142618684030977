@import '../../variables';
@import '../../functions';

#FrontPage {
    // Set max content width.
    max-width: $max-content-width;
    width: 100%;
    margin: {
        left: auto;
        right: auto;

    }
    // Everything is full-width on mobile, so we add some gutters.
    @include small-screen {
        padding: {
            left: 15px;
            right: 15px;
        }
    }
    .LogoSection {
        // Center image.
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: flex-start;
        // Spacing.
        padding-top: 38px;
        img {
            // Set img size.
            width: 158px;
            // Shrink image.
            @include small-screen {
                width: 94px;
            }
        }
    }
    .SearchBarSection {
        // Center image.
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: flex-start;
        // Spacing.
        padding-top: 70px;
        @include small-screen {
            padding-top: 40px;
        }
        .SearchBarContainer {
            width: 50%;
            @include medium-screen {
                width: 66%;
            }
            @include small-screen {
                width: 100%;
            }
        }
    }
    .ContentContainer {
        // Center content.
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: flex-start;
        // No company selected.
        .NoCompanySelectedText {
            // Spacing.
            padding-top: 133px;
            // Text align
            text-align: center;
        }
        // Company selected: showing info.
        .CompanyInfoContainer {
            padding-top: 38px;
            width: 50%;
            @include medium-screen {
                width: 66%;
            }
            // Everything is full width in mobile
            @include small-screen {
                width: 100%;
            }
            .CompanyHeaderInfo {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: flex-start;
                // Put on top of eachother in mobile
                @include small-screen {
                    flex-flow: column nowrap;
                    justify-content: flex-start;
                    align-items: center;
                }
                span {
                    font-size: 32px;
                    line-height: 40px;
                    color: #231f20;
                    @include small-screen {
                        font-size: 26px;
                        line-height: 32px;
                    }
                }
                a {
                    // Font
                    font-size: 16px;
                    line-height: 40px;
                    .FaChevronCircleRight {
                        // Add space between icon
                        margin-right: 10px;
                    }
                }
            }
            .ChartWrapper {
                margin-top: 37px;
                @include small-screen {
                    margin-top: 22px;
                }
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
            }
            .BottomText {
                // Font and text.
                font-weight: bold;
                font-size: 16px;
                text-align: center;
                // Add space.
                margin-top: 68px;
                // Smaller font and space on mobile
                @include medium-and-small-screen {
                    font-size: 14px;
                    margin-top: 52px;
                }
            }
        }
    }
}