@import '../../../variables';

#CompositePanel {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    .ErrorWrapper {
        color: red;
    }
    .ChartWrapper {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: flex-start;
    }
    p {
        color: #757575;
        padding-left: 5px;
    }
    .InfoWrapper {
        margin: {
            top: 20px;
            bottom: 30px;
        }
        background-color: #F8F8F8;
        h3 {
            // Font styles.
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 40px;
            color: #231F20;
            // Placement.
            margin: 0px;
            padding-left: 10px;
        }
        table {
            margin: 0px;
            tr {
                td {
                    border: 0px;
                    padding: {
                        top: 0px;
                        left: 10px;
                        right: 10px;
                        bottom: 0px;
                    }
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 30px;
                    color: #231F20;
                    &:last-of-type {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 30px;
                        text-align: right;
                        color: #231F20;
                    }
                    &.Positive {
                        color: #278465;
                    }
                    &.Negative {
                        color: #F85F42;
                    }
                }
            }
        }
    }
}