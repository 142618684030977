.PanelWrapper {
    margin: 10px;
    width: calc(100% - 20px);
    h2 {
        color: #231F20;
    }
    .SectionHeaderHorizontalLine {
        height: 3px;
        width: 100%;
        background: linear-gradient(90deg, #EF8AA1 0%, #F48363 32.47%, #278465 71.69%, #3A556A 99.09%);
        margin-bottom: .5rem
    }
}