@import '../../../variables';
@import '../../../functions';

#BiggestCompaniesList {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    .ErrorWrapper {
        color: red;
    }
    .TableHeaderWrapper {
        // Space from table
        padding-bottom: 10px;
        // Flex settings.
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        p {
            margin: 0px;
            color: #757575;
            &.FirstTableHeader {
                padding-left: 5px;
            }
            &.LastTableHeader {
                padding-right: 5px;
            }
            &:hover {
                cursor: pointer;
            }
        }
        .dropdown {
            p {
                padding-left: 10px;
                color: #3A556A;
            }
            #SectorFilterMenu {
                border-radius: 2px;
                padding: {
                    top: 0px;
                    bottom: 0px;
                }
                a {
                    font-weight: 500;
                    font-style: normal;
                    font-size: 13px;
                    line-height: 30px;
                    color: #231F20;
                    padding: {
                        top: 2px;
                        left: 10px;
                        right: 10px;
                        bottom: 2px;
                    }
                    &.Selected, &:hover {
                        background: linear-gradient(269.86deg, #2E5B8A 9.5%, #278465 96.07%);
                        color: white;
                        font-weight: 600;
                    }
                }
            }
        }
        .TableHeaderJustifyRight {
            flex: 1 0 50px;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;
            align-items: center;
        }
    }
    table {
        width: 100%;
        height: 100%;
        margin-bottom: 0px;
        tr {
            td {
                &.TextColumn {
                    text-align: left;
                }
                vertical-align: middle;
                text-align: right;
                padding: {
                    top: 0px;
                    left: 5px;
                    right: 5px;
                    bottom: 0px;
                }
                border: 0px;
                a {
                    color: #231F20;
                }
                color: #231F20;
                span {
                    display: inline-block;
                    width: 40px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 38px;
                    color: #757575;
                    &.Positive {
                        color: #278465;
                    }
                    &.Negative {
                        color: #F85F42;
                    }
                }
            }
            &:nth-of-type(odd) {
                background-color: #F8F8F8;
            }
        }
    }
    .PaginationWrapper {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;
        align-items: center;
        border: {
            top: 2px solid #E4E3E3;
            bottom: 2px solid #E4E3E3;
        }
        padding: {
            top: 10px;
            bottom: 10px;
        };
        div {
            display: flex;
            align-items: center;
            justify-content: center;
            // Length variables.
            height: 30px;
            flex: 0 0 30px;
            border-radius: 15px;
            // Font styles.
            font-weight: 600;
            font-style: normal;
            font-style: 14px;
            line-height: 21px;
            // Color.
            color: #2E5B8A;
            // Alignment.
            text-align: center;
            vertical-align: middle;
            // Stop selecting text.
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
            &.Disabled {
                color: lightgray;
            }
            &.Active {
                background: linear-gradient(18.08deg, #278465 8.35%, #2E5B8A 81.35%);
                color: white;
            }
            &:not(.Disabled) {
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}